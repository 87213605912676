import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Item,
} from 'semantic-ui-react';
import RehypeReact from 'rehype-react';

// eslint-disable-next-line new-cap
const renderAst = new RehypeReact({
  createElement: React.createElement,
}).Compiler;

// noinspection JSUnresolvedVariable
const PostLink = ({ post }) => (
  <Item>
    <Item.Content>
      <Item.Header>
        <Link to={`/blog/post/${post.frontmatter.datePath}/${post.frontmatter.slug}`}>
          {post.frontmatter.title}
        </Link>
      </Item.Header>
      <Item.Meta>
        {`(${post.frontmatter.date})`}
      </Item.Meta>
      {post.frontmatter.tags && (
        <Item.Meta>
          <span>tags: </span>
          {post.frontmatter.tags.join(', ')}
        </Item.Meta>
      )}
      <Item.Description>
        {renderAst(post.excerptAst)}
      </Item.Description>
    </Item.Content>
  </Item>
);

PostLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  post: PropTypes.any.isRequired,
};

export default PostLink;
