import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
} from 'gatsby';
import {
  Container,
  Header,
  Item,
  Divider,
  Icon,
  Breadcrumb,
} from 'semantic-ui-react';
import Link from 'gatsby-link';
import PostLink from '../../components/post-link';

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);
  return (
    <Container>
      <Breadcrumb size="massive">
        <Breadcrumb.Section link="/"><Link to="/">Home</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link="/blog"><Link to="/blog">Blog</Link></Breadcrumb.Section>
      </Breadcrumb>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="file alternate outline" />
          Posts
        </Header>
      </Divider>
      <Item.Group divided className="blog-post-items">
        {Posts}
      </Item.Group>
    </Container>
  );
};

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerptAst(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            datePath: date(formatString: "YYYY/MM/DD")
            slug
            title
            tags
          }
        }
      }
    }
  }
`;
